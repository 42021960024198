.flex {
    display: flex;
    justify-content: center;
}

.flex div {
    flex-basis: 50%;
}

.main_box {
    padding: 3rem;
}

.details_container {
    padding: 5rem;
    margin-top: 8rem; 
    margin-bottom: 8rem;
}

.scroollbar::-webkit-scrollbar {
    width: 5px;
}

.scroollbar::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.scroollbar::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-image: linear-gradient(180deg, #31C75A 0%, #105157 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}



@media screen and (max-width: 700px) {
    .flex {
        flex-direction: column;
    }
    .main_box {
        padding: 0rem;
    }
    .flex div {
        flex-basis: 100%;
    }

    .details_container{
        padding: 2rem;
    }
}