.main {
  margin-top: 180px;
}

.left-signIn {
  border-radius: 20px 0px 0px 20px;
}

.right-signIn {
  text-align: center !important;
  background-color: #d6e4d7;
  border-radius: 0px 20px 20px 0px;
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
}

.right-signIn input {
  background-color: #fff;
  /* border: 2px solid rgba(49, 199, 90, 1); */
  border-radius: 4px;
}

.right-signIn input:focus {
  outline: none;
  border: none;
}

.pass_div {
  position: relative;
}

.pass_div svg {
  position: absolute;
  top: 35%;
  right: 15%;
}

.back_container {
  max-width: 500px;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;
}

.back_container svg {
  fill: #31c75a;
}

.back_container span {
  text-decoration: underline;
  color: #31c75a;
  cursor: pointer;
  font-weight: 700;
}

.google_login {
  background-color: #ffffff;
  display: flex;
  gap: 24px;
  padding: 0 8px;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 185px;
  margin: 1rem auto;
  height: 40px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.google_login img {
  width: 18px;
  height: 18px;
}

.google_login p {
  font-family: "Roboto" !important;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.signin-btn {
  width: 50%;
  outline: 0;
  border: 0;
  padding: 15px 5px;
  cursor: pointer;
  color: white;
  border-radius: 25px !important;
  background: linear-gradient(
    90deg,
    rgba(49, 199, 90, 1) 0%,
    rgba(99, 228, 85, 1) 100%
  );
}

.custom-file__input {
  visibility: hidden;
}

/* mobile devices */
@media only screen and (max-width: 688px) {
  .left-signIn {
    border-radius: 0;
    height: 200px;
    overflow: hidden;
  }

  .right-signIn {
    padding-bottom: 15px;
    border-radius: 0;
  }

  .main {
    margin-top: 100px;
  }
}

/* tablate devices */

@media only screen and (min-width: 689px) and (max-width: 992px) {
}
