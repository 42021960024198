

/* ================== Message Styling================ */
/* .message_container {
    grid-column: 2 / -1;
    height: 90%;
    padding: 20px 30px;
    background: #EEFFF7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    opacity: 1;
} */
.message_container {
    position:fixed;
    /* margin-right: 40px; */
    bottom: 15%;
    right: 5%;
    height: 500px;
    width: 400px;
    padding: 20px 30px;
    background: #EEFFF7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    opacity: 1;
}

.message_container_two {
    opacity: 0 !important;
    grid-column: 2 / -1;
    height: 0%;
    padding: 20px 30px;
    background: #EEFFF7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
}

.chat_heading {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    position: relative;
}

.chat_heading svg {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.messages {
    padding: 10px;
    /* max-height: 75%;
    min-height: 40%; */
    height: 500px;
    overflow-y: scroll;

    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 30px;
}

.messages::-webkit-scrollbar {
    width: 5px;
}

.messages::-webkit-scrollbar-track {
    background: #ddd; 
  }

.comming {
    max-width: 85%;
    width: max-content;
    background-color: #1BD760;
    color: #fff;
    padding: 22px;
    border-radius: 4px 32px 32px 32px;
}

.sending {
    /* margin-left: 15%; */
    max-width: 85%;
    width: max-content;
    text-align: right;
    align-self: flex-end;
    padding: 22px;
    background: #E9E9F2;
    border-radius: 32px 32px 4px 32px;
}

.comming .text,
.sending .text {
    word-wrap: break-word;
}

.form input {
    padding: 10px;
    width: 100%;
    border: 1px solid #1BD760;
    border-radius: 8px;
    outline: none;
}

.user input {
    padding: 10px;
    width: 100%;
    border: 1px solid #1BD760;
    outline: none;
}



.btn {
    border: none;
    background-color: #1BD760;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
}

@media screen and (max-width: 1100px) {

    .video_container_double {
        grid-template-columns: 1fr 300px;
    }
    
}
@media screen and (max-width: 550px) {
    .message_container {
        width: 300px;
    }
}
/* 
@media screen and (max-width: 780px) {
    .vid {
        width: 80%;
    }

    .patient { 
        width: 100px;
        height: 100px;
    }
} */
