.meet-btns {
    margin: 15px;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center; */
}

.meet-link {
    margin: 15px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
}

.share-link {
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 15px;
    outline: none;
    border: 1px solid greenyellow;
}

.send-link {
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    background: linear-gradient(90deg, rgba(49, 199, 90, 1) 0%, rgba(99, 228, 85, 1) 100%);
}

.report-btn {
    cursor: pointer;
    color: white;
    background-color: #31C75A;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 15px;
    outline: none;
    border: none;
    transition: 0.5s;
    width: 100%;
}

.bar-line {
    width: 100% !important;
    padding: 5px 55px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
}

.patients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
    align-items: center;
}

.appointment-first {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    padding: 5px 25px;
}

#data-filter {
    padding: 5px 15px;
    outline: 0 !important;
    border-width: 1px !important;
    border-radius: 15px;
}

.appointment-table {
    width: 100px;
    overflow-x: scroll !important;
}

.table-container {
    max-width: 1105px;
}

.report-btn:hover {
    background-color: #65503A;
}

.join-btn {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: #FFC100;
    width: 100%;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 15px;
    outline: none;
    border: none;
    transition: 0.5s;
}

.join-btn:hover {
    background-color: #31C75A;
}

/* ========RESPONSIVENESS========== */
@media screen and (max-width: 900px) {
    .container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}


@media screen and (max-width: 768px) {
    .appointment-first {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: space-between;
        align-items: center;
        padding: 5px 25px;
    }

    .table-container {
        max-width: 450px;
    }
}

@media screen and (max-width: 550px) {

    .patientBox {
        width: 300px !important;
    }
}

@media screen and (max-width: 500px) {
    .table-container {
        max-width: 350px;
    }
}

.WaitForApproval .css-lcgnqy-MuiContainer-root {
    max-width: 100% !important;
}