.fellowship-banner {
  margin-top: 5%;
}
.fellowship-button {
  transition: all 0.5s ease-in-out !important;
}
.fellowship-button:hover {
  color: #3cb669;
  /* transition: all 0.5s; */
}
@media only screen and (max-width: 768px) {
  .fellowship-banner {
    margin-top: 25%;
  }
}
