.preset:hover {
  cursor: pointer;
  background-color: gray;
}

.preset:hover .preset-text {
  color: black;
}
.countup-number {
  display: inline-block;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease-out;
}

.countup-visible {
  opacity: 1;
  transform: translateY(0%);
}
