.enganement_container {
    background-color: rgba(49, 199, 90, .3);
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 50px;
}

.box {
    position: relative;
    padding: 20px;
    background-color: #fff;
    margin: 10px;
    border-radius: 8px;
}

.box svg {
    position: absolute;
    top: -18px;

    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    fill: #31C75A;
}

.box h3 {
    margin: 0;
    font-size: 32px;
}