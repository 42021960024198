.heading {
  text-align: center;
  font-size: 38px;
}

.message_container {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background-color: #31C75A;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0,0,0,.2);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

@media screen and (max-width: 600px) {
  .message_container{
    bottom: 1rem !important;
    right: 1rem !important;
  }
}

.message_container svg {
  fill:#fff;
  width: 50px;
  height: 50px;
}