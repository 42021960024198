@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
}
@font-face {
  font-family: "DM Sans";
  src: url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&display=swap");
}
.psychologist-box {
  text-align: center;
  justify-content: center;
  padding: 5px 15px 15px 15px;
  border-radius: 20px;
  background-color: #e9faed;
  box-shadow: 0px 17px 15px 5px #dad6d6;
}

.fpZpvl {
  border-radius: 10px;
}

.psychologist-container {
  padding: 38px;
}
.swiper-button-next {
  right: 0px;
}
.psychologist-img {
  margin-top: 1rem;
  width: 10rem !important;
  height: 10rem !important;
  object-fit: contain;
  border-radius: 100px;
}

.selectedPsychologist-box {
  text-align: center;
  justify-content: center;
  padding: 0px 10px 10px 10px;
  border-radius: 20px;
  /* background-color: #E9FAED;
      box-shadow: 0px 17px 15px 5px #dad6d6; */
}

.selectedPsychologistImg {
  width: 100%;
  height: 16rem !important;
  width: 16rem !important;
  border-radius: 25px !important;
}

.psychologist-name {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffc100;
  margin-bottom: 0px;
}

.psychologist-title {
  font-size: 15px;
  font-weight: 500;
  margin: 5px;
}

.psychologist-recommend {
  color: green;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 0;
}

.group-btn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.swiper-button-prev {
  color: #63e455;
}

.swiper-button-next {
  color: #63e455;
}

.experience {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgb(49, 199, 90);
  color: #fff;
  padding: 2px;
}
.group-37417 {
  width: 295px;
  height: 440px;
}

.rectangle-59-home {
  width: 295px;
  height: 440px;
  background-color: linear-gradient(0deg, #e9faed, #e9faed),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 5px solid #e9faed;
  border-radius: 10px;
  box-shadow: 0px 4px 10px -28px #00000040;
  transition: all;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.rectangle-59-all-psychologist {
  background-color: linear-gradient(0deg, #e9faed, #e9faed),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 5px solid #e9faed;
  border-radius: 10px;
  box-shadow: 0px 4px 10px -28px #00000040;
  transition: all;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.rectangle-59-all-psychologist:hover {
  border: 5px solid #31c75a;
  transition: all;
  transition-duration: 0.5s;
}
.rectangle-59-home:hover {
  border: 5px solid #31c75a;
  transition: all;
  transition-duration: 0.5s;
}
.avatar {
  position: relative;
  width: 151px;
  height: 151px;
}
.image-60 {
  /*  width: 151px;
    height: 151px; */
  position: absolute;
  top: 250px;
  left: 121px;
  border-radius: 75.5px;
}
.pricing-text {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #1d9bf0;
  margin-top: -20px;
}
.details {
  width: 100%;
}
.details-text-home {
  font-family: "Poppins";
  color: #3f3d56;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: -15px;
  padding: 0 10px;
  /* max-width: fit-content; */
}
.booking-button-custom-home {
  width: 250px;
  height: 30px;
  background: #31c75a;
  padding: 10px;
  border-radius: 10px;
  /* margin: 0 5px; */
  /* margin-left: 2dvh !important; */
  margin-top: 15px;
  /*  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center; */
  transition: transform 0.2s;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.booking-button-custom-home-parent {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* justify-items: center; */
}
.booking-button-custom-home {
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-button-custom-home:hover {
  border: 4px solid #e9faed;
  background: #ffffff;
  transform: scale(0.9);
}

.booking-button-custom-home p {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.booking-button-custom-home:hover p {
  color: #686868;
}
.review {
  /* margin-bottom: ; */
  /* font-family: "DM Sans"; */
}
.faded-text {
  /* Set the same font size as sliced text */
  font-family: "Poppins";
  color: transparent; /* Make text color transparent */
  background: linear-gradient(
    to right,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.2)
  );
  /* Add a gradient background to create the fade effect */
  background-clip: text;
}

.psychologist-image-square {
  position: relative;
}
.verified-image {
  position: absolute;
  bottom: 0;
  right: 0;
}
