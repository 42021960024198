.ordinal {
  background-color: white !important;
  padding: 1% 0;
}
.menu-bar-primary-button {
  font-family: DM Sans, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize !important;
}
.new {
  background-color: white !important;
}

.sub-menu {
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.sub-menu p {
  text-align: center;
}
