.btn_container {
    display: flex;
    justify-content: flex-end; 
    /* margin-right: 1rem; */
}

@media only screen and (max-width:688px) {
    .EditProfile {
        padding: 2rem 1.5rem 2rem 0rem !important;

    }

    .footerCenter {
        text-align: center;
        margin: 0 !important;
        padding: 0 !important;
    }

    .FooterPsycureLeaf {
        display: none;
    }

    .footerText {
        width: 100% !important;
        padding-right: 0rem !important;

    }

    .changePadding {
        padding: 0 !important;
    }

    .psychologistSignIn {
        display: flex !important;
        justify-content: center !important;
        margin: auto !important;
    }

    .socialMedia {
        padding: 0 !important;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .socialMediaSingle {
        display: flex;
        justify-content: center;
    }

    .FooterPsycureLogo {
        display: flex;
        margin: auto;
    }

    .btn_container {
        justify-content: center;
    }
    
}