.time-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    justify-items: end;

}

.date-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.align-box {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    align-items: start;
}

.boxStyle {

    padding: 2rem;
    margin-top: 8rem;
}

.boxStyle_inside {
    background: #F5F5F5;
    padding: 2rem;

}

@media screen and (max-width: 550px) {
    .boxStyle {
        padding: 0;
        margin-top: 2rem;
    }
}