    .WaitForApproval {
        background-image: url(./../../../assets/images/404bg.png);
        background-size: cover;
        background-repeat: no-repeat;

    }

    .WaitForApproval #notfound {
        position: relative;
        height: 100vh;
    }

    .WaitForApproval #notfound .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .WaitForApproval .notfound {
        /* max-width: 800px; */
        width: 100%;
        line-height: 1.4;
        text-align: center;
    }

    .WaitForApproval .notfound .notfound-404 {
        position: relative;
        height: 240px;
    }

    .WaitForApproval .notfound .notfound-404 h1 {
        font-family: 'Montserrat', sans-serif;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 5rem;
        font-weight: 900;
        margin: 0px;
        color: rgb(49, 199, 90);
        text-transform: uppercase;
        /* letter-spacing: -40px;
        margin-left: -20px; */
    }

    .WaitForApproval .notfound .notfound-404 h1>span {
        text-shadow: -8px 0px 0px rgb(255, 214, 11);
    }

    .WaitForApproval .notfound .notfound-404 h3 {
        font-family: 'Cabin', sans-serif;
        position: relative;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #7e5600;
        margin: 0px;
        letter-spacing: 3px;
        padding-left: 6px;
    }

    .WaitForApproval .notfound h2 {
        font-family: 'Cabin', sans-serif;
        font-size: 3rem;
        font-weight: 400;
        text-transform: uppercase;
        color: #7e5600;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .WaitForApproval {
        margin-top: -5rem;
    }

    .WaitForApproval button {
        box-shadow: 1px 2px 10px rgb(68 68 68 / 20%);
        border-radius: 100px;
        background: linear-gradient(rgb(49, 199, 90), rgb(49, 199, 90));
        border: 0;
        border-width: 3px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: white;
        letter-spacing: 0.8px;
        transition: 0.5s;
        margin-right: 20px;
        padding: 10px 15px;
    }


    
@media screen and (max-width: 1400px) {
    .WaitForApproval .notfound .notfound-404 h1 {
        font-size: 3.2rem;
    }
  }