/* .table {
    width: 300px;
} */

@media screen and (max-width: 900px) {
    .container {
        padding-top: 20px !important;
        padding-bottom: 0 !important;
    }

    .img {
        margin-top: 0 !important;
    }
    .spacer{
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
}