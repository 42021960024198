.elementContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.builder-input {
    width: 100%;
    outline: none;
    border: 1 px solid #31C75A !important;
    padding: 10px;
    border-radius: 5px;

}

.builder-file {
    outline: none;
    width: 100%;
    /* width: calc(43%); */

}

.builder-input-des {
    width: 100%;
    outline: none;
    border-radius: 5px;
    border: 1 px solid #31C75A;

}

.builder-option {
    outline: none;
    border: none;
    padding: 10px;
    border-bottom: 1px solid #31C75A;

}

.builder-btn {
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #31C75A;
    color: white;
    padding: 10px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 50px;
    float: right;
}

.written_container input {
    width: 100%;
    outline: none;
    border: 1px solid #31C75A !important;
    padding: 10px;
    border-radius: 5px;
}
.written_container textarea {
    width: 100%;
    height: 150px;
    outline: none;
    border: 1px solid #31C75A !important;
    padding: 10px;
    border-radius: 5px;
    resize: none;
}
