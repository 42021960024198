.topbar {
    display: none !important;
    background-color: transparent !important ;
    color: black !important;
    box-shadow: none !important;
}

@media screen and (max-width: 600px) {
    .topbar {
        display: block !important;
    }
}