

@media only screen and (max-width: 600px) {
    #services{
        margin-top: 2rem !important;
    }
  }

.service{
    background-color: #fff;
    margin-top: 60px;
}
.service h2{
    margin-bottom: 0;
}

.service img {
    width: 100%;
}

.service button {
    border: none;
    padding: 1rem;
    background-color: #31C75A;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.read_more {
    margin: "3rem 0"
}

.read_more  img{
    margin: 0 auto;
    text-align: center;
}

.read_more button {
    border: none;
    padding: 1rem;
    background-color: #31C75A;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}