@font-face {
  font-family: "DM Sans";
  src: url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&display=swap");
}

.payment-table {
  border-radius: 25px !important;
}

.payment_form {
  width: 100%;
}

/* .payment_form .input_container {
      display: flex;
  
  } */
.payment_form input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
}
.payment_form input:focus {
  outline: none;
}

.hidden {
  display: none;
}

.chose {
  background-color: #fff;
}
.session-header {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.individual-counseling {
  width: 440px;
  height: 198px;
  top: 1408px;
  left: 301px;
  padding: 11px, 16px, 11px, 16px;
  border-radius: 12px;
  border: 1px;
  gap: 12px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef);
  border: 1px solid #e3e8ef;
}
.order-1 {
  width: 392px;
  height: 105px;
  top: 1434px;
  left: 325px;
  border-radius: 8px;
  border: 1px solid #f8fafc;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 10px;
}

.psychologist-image {
  border-radius: 8px;
  border: 1px;
}
.individual-counseling-text {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
}
.order-1-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.individual-counseling-price p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: right;
  color: #111729;
}
.size-time {
  font-family: "DM Sans", sans-serif;
  color: #677489;

  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
}
.size-start-time {
  font-family: "DM Sans", sans-serif;
  color: #111729;

  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
}
.session-type {
  margin-left: 125px;
  margin-top: 20px;
}
.session-gray {
  font-family: " DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #677489;
}
.individual-session {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
}

.input-container {
  display: flex;
  align-items: center;

  border-radius: 12px;
  padding: 11px 16px 11px 12px;
  width: 440px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef);
  border: 1px solid #e3e8ef;
  /* Adjust the width as needed */
}

.promo-icon {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 20px;
  margin-top: 7px;
  color: #ffcc00; /* Yellow color for the promo icon */
}

.promo-input {
  flex: 1;
  border: none;
  padding: 5px;
}
.promo-input::placeholder {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #677489;
}
.apply-button {
  background: #36ca5a; /* Green color for the Apply button */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.promo-code-things {
  margin: 50px 0;
}
.promo-code-label {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.005em;
  text-align: left;
}

.session-details {
  width: 440px;
  height: 102px;
  top: 1778px;
  left: 301px;
  gap: 12px;
}
.session-details-each {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #677489;
}
.session-values-each {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #111729;
}
.subtotal-details-text {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #111729;
}
.subtotal-details-price {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #111729;
}
.payment-info {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #111729;
}
.phone-input {
  width: 440px;
  height: 48px;
  padding: 11px, 16px, 11px, 16px;
  border-radius: 12px;
  border: 1px;
  gap: 12px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef) !important;
  border: 1px solid #e3e8ef !important;
}
.phone-label {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #111729;
}
.phone-input::placeholder {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #677489;
}
.zip-input {
  width: 167px !important;
  height: 48px !important;
  padding: 11px, 16px, 11px, 16px;
  border-radius: 12px;

  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef) !important;
  border: 1px solid #e3e8ef !important;
}
.zipcode-city {
  display: flex;
  gap: 20px;
}

.payment_form {
  margin-top: 185px;
}
.pay-button {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: center;
  width: 360px;
  height: 48px;
  top: 1898px;
  left: 984px;
  padding: 11px, 24px, 11px, 24px;
  border-radius: 12px;
  gap: 8px;
  background: #36ca5a;
  color: #ffffff;

  border: none;
}
.pay-button:hover {
  cursor: pointer;
}
.billing-info {
  width: 359px;
}
@media only screen and (max-width: 768px) {
  .pay-button {
    width: 349px;
    height: 60px;
  }
  .billing-info {
    width: 349px;
    height: 65.93px;
  }
  .payment_form {
    margin-top: 0px;
  }
  .session-details {
    width: 350px;
    height: 82.05px;
  }
  .input-container {
    width: 350px;
  }
  .individual-counseling {
    width: 350px;
  }
  .individual-counseling-text {
    font-family: "DM Sans", sans-serif;
    font-size: 12.7px;
    font-weight: 700;
    line-height: 20.96px;
    letter-spacing: 0.005em;
    text-align: left;
  }
  .session-type {
    margin-left: 85px;
    margin-top: 20px;
  }
  .individual-session {
    font-size: 12.7px;
    /* width: 135.7px; */
  }
}
