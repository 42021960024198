.paymentDetails h4 {
    line-height: 1rem;
}

.paymentDetails h2 {
    line-height: 1rem;
}

.psy_img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}