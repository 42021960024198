@font-face {
  font-family: "DM Sans";
  src: url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&display=swap");
}
.payment-table {
  border-radius: 25px !important;
}

.payment_form {
  width: 100%;
}

/* .payment_form .input_container {
      display: flex;
  
  } */
.payment_form input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
}
.payment_form input:focus {
  outline: none;
}

.hidden {
  display: none;
}

.chose {
  background-color: #fff;
}
.time-button-parent {
  display: flex;
  justify-content: center;
}
.time-button {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef);
  border: 1px solid #e3e8ef;
  width: 214px;
  height: 48px;
  padding: 11px, 12px, 11px, 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.time-button button {
  border: none;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e3e8ef, #e3e8ef);
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #677489;
}
.custom-box-parent {
  display: flex;
  justify-content: center;
}
.custom-box {
  width: 211px;
  height: 176px;
  top: 938px;
  left: 1008.77px;
  padding: 8px, 0px, 8px, 0px;
  border-radius: 8px;
  background: #000000;

  box-shadow: 0px 1px 3px 0px #130a2e21;

  box-shadow: 0px 3px 14px 0px #130a2e08;

  box-shadow: 0px 8px 32px 0px #130a2e12;

  box-shadow: 0px 30px 84px 0px #130a2e14;
}
.select-time:hover {
  background: #36ca5a !important;
}
.review {
  margin-top: -20px;
  margin-bottom: 20px;
}
.psychologist-name-head {
  font-family: "DM Sans", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffc100;
}
.psychologist-yourself {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #3f3d56;
}
.frame-3 {
  width: 257.83px;
  height: 257.83px;
  top: 200.46px;
  left: 286px;
  padding: 15.08px;
  gap: 15.08px;
  /* border-radius: 75.5px; */
}
.category-grid {
  display: flex !important;
  gap: 20px;
}
.category-grid-item {
  width: 149.04px;
  height: 31.47px;
  padding: 7.24px;
  border-radius: 35.45px;
  gap: 7.24px;
  background: #ffc100;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}
.degree-name {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #3f3d56;
}
@media only screen and (max-width: 768px) {
  .frame-3 {
    width: 90%;

    /* border-radius: 75.5px; */
  }
  .review {
    margin-top: -20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .psychologist-name-head {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    /* text-align: center;
     */
    display: flex;
    justify-content: center;
  }
  .psychologist-yourself {
    margin-top: -10px;
  }
}
