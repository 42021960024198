.review-container {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center !important;
}

#profile-img {
  display: inline-block;
  width: 50px !important;
  height: 50px !important;
  border-radius: 25px !important;
}

.review-box {
  /* display: grid;
    grid-template-columns: minmax(1fr, 1fr); */
  /* justify-content: center;
    align-items: center;
    place-content: center; */
  width: 100%;
  min-height: 300px;
  border-radius: 12px;
  padding: 20px;
  border-radius: 20px;
  background-color: #e9faed;
  box-shadow: 0px 17px 15px 5px #dad6d6;
}
.fellowship-box {
  width: 100%;
  min-height: 100px;
  border-radius: 12px;
  padding: 20px;
  border-radius: 20px;
  /* text-align: center; */
  /* background-color: #e9faed; */
  /* box-shadow: 0px 17px 15px 5px #dad6d6; */
}
