.event-box:hover {
  cursor: pointer;
}
.summit-submit {
  background-color: transparent;
  border: 1px solid #31c75a;
  border-radius: 20px;
  font-size: large;
  /* text-transform: capitalize; */
  transition: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.summit-submit:hover {
  cursor: pointer;
  background-color: #31c75a;
}

@media (max-width: 600px) {
  .summit-header {
    margin-top: 25% !important;
    .summit-name-text {
      font-size: 30px !important;
    }
  }
}
