.input-field {
    width: 80%;
    padding: 15px;
    outline: 0 !important;
    border-width: 1px !important;
    border-radius: 15px;
}

.send-btn {
    width: 80%;
    outline: 0;
    border: 0;
    padding: 10px;
    cursor: pointer;
    color: white;
    border-radius: 25px !important;
    background: linear-gradient(90deg, rgba(49, 199, 90, 1) 0%, rgba(99, 228, 85, 1) 100%);
}