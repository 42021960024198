@font-face {
  font-family: "DM Sans";
  src: url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&display=swap");
}
@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
}
.play-pause-button {
  opacity: 0;
}
.unwindmind-text {
  font-family: "DM Sans", sans-serif !important;
  text-align: justify;
  font-size: 1rem !important;
}
.parent-video-component:hover .play-pause-button {
  opacity: 1;
}

.quote-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Georgia", serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quote-container:hover {
  transform: scale(1.03); /* Slight zoom effect on hover */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow */
}

blockquote {
  font-size: 1.5em;
  font-style: italic;
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}

cite {
  display: block;
  margin-top: 15px;
  font-size: 1em;
  color: #555;
  font-weight: bold;
  transition: color 0.3s ease;
}

.quote-container:hover blockquote {
  color: #d35400; /* Change text color on hover */
  transform: translateY(-5px); /* Smooth upward movement */
}

.quote-container:hover cite {
  color: #7f8c8d; /* Subtle color change for author */
}

/* Adding wave-like fluid animation */
.quote-container:hover blockquote::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #d35400, #f39c12);
  transform: translateX(-50%);
  animation: wave 0.6s ease-in-out infinite;
}

@keyframes wave {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.award-container {
  max-width: 78%;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.award-header {
  background: linear-gradient(45deg, #36ca5a, #8be78b);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
}

.award-title {
  font-size: 2.2rem;
  margin: 0;
  text-transform: uppercase;
}

.award-details {
  margin-top: 20px;
  padding: 15px;
}

.award-subtitle {
  color: black;
  font-size: 1.8rem;
  margin-bottom: 10px;
  /* font-weight: 900; */
}

.award-total {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 15px;
}

.award-list {
  list-style: none;
  padding: 0;
}

.award-item {
  font-size: 1.2rem;
  margin: 10px 0;
  text-align: left;
  color: #4a5568;
}

.award-item strong {
  color: black;
}

.award-item::before {
  content: "★";
  color: #ffd700;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .award-title {
    font-size: 1.6rem;
  }

  .award-subtitle {
    font-size: 1.4rem;
  }

  .award-total {
    font-size: 1.2rem;
  }

  .award-item {
    font-size: 1rem;
  }
}

.media-showcase-container {
  padding: 50px;
  /* background: linear-gradient(135deg, #36ca5a, #2c9f45);
   */
  background-color: #3cb669;
  color: #fff;
  text-align: center;
}

.showcase-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-family: "Arial", sans-serif;
}

.media-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
}

.media-item {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.media-item:hover {
  transform: translateY(-10px);
}

.media-img {
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  border-bottom: 2px solid #36ca5a;
}

.media-info {
  padding: 20px;
}

.media-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.media-info p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.media-link {
  color: #36ca5a;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.media-link:hover {
  color: #2c9f45;
}
@media (max-width: 768px) {
  .media-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .media-logo {
    width: 70%;
  }
}
