.navigation {
  width: 15em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  place-content: center;
  gap: 10px;
  margin-top: 25px;
}

.sub-menu_drawer {
  background-color: #efefef;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
}
