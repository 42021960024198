/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,700&family=Ruda:wght@400;500;600;700;800;900&display=swap");
/* @import url('https://fonts.cdnfonts.com/css/avenir-next-lt-pro'); */

html {
  scroll-behavior: smooth;
}

/* Avenir Next LT Pro */
@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/Regular.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: bold;
  font-weight: bold;
  src: url("../src/assets/fonts/bold.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Avenir Next LT Pro", sans-serif;
}

img {
  pointer-events: none;
}

.headin {
  font-size: 38px;
  text-align: center;
  margin-bottom: 0rem;
}

p {
  line-height: 1.6;
}

.success_alert {
  position: fixed;
  top: 10%;
  right: 100px;
  max-width: 300px;
  background-color: #31c75a;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
}

.error_alert {
  position: fixed;
  top: 10%;
  right: 100px;
  max-width: 300px;
  background-color: #c02060;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
}

.img_container {
  max-width: 600px;
  margin: 0 auto;
}

.img_container img {
  width: 100%;
  height: 100%;
}

.person_card {
  background-color: rgba(49, 199, 90, 0.1);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  margin-top: 160px;
  text-align: center;
}

.person_card .img_container {
  text-align: center;
}

.table_center {
  text-align: center !important;
}

.person_card .img_container img {
  margin-top: -50%;
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.person_card h3 {
  margin-top: 15px;
  font-size: 18px;
  color: #31c75a;
  text-align: center;
}

.person_card p {
  text-align: center;
  font-style: italic;
  color: #4d4d4d;
  font-size: 16px;
}

.textarea {
  width: 100%;
  border: 2px solid #31c75a;
  height: 150px;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
  margin-top: 10px;
}

.textarea::placeholder {
  font-size: 16px;
}

.textarea:focus {
  outline: none;
}

.topbar {
  display: none;
  background-color: red;
}

.workshop_details_title {
  font-size: 2.2rem;
}

.workshop {
  padding-top: 5rem;
  display: grid;
  gap: 2.2rem;
  grid-template-columns: repeat(3, 1fr);
}

.worshop_heading {
  font-weight: bold;
  font-size: 18px;
}

.worshop_description {
  margin-top: 0;
}

.workshop_btn {
  background-color: #31c75a;
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  border: none;
}
.workshop_btn:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .topbar {
    background-color: red;
    display: block;
  }
  .workshop_details_title {
    font-size: 1.5rem;
  }
  .workshop {
    padding-top: 2rem;
    grid-template-columns: 1fr;
  }
  .worshop_description {
    display: none;
  }
  .worshop_heading {
    font-size: 14px;
    margin: 3px 0;
  }
  .workshop_btn {
    font-size: 10px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 500px) {
  .headin {
    font-size: 20px;
  }
}
