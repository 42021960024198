.bloger {
  background-color: #f1f1f1;
  border-radius: 0.4rem;
  text-align: center;
  padding: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.bloger .bloger_details {
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.other_blog {
  background-color: #f1f1f1;
  margin-top: 1.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* position: fixed;
  top: 50px; */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
