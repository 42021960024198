.main {
    margin-top: 180px;
}

.left-signIn {
    border-radius: 20px 0px 0px 20px;

}

.right-signIn {
    text-align: center;
    background-color: #D6E4D7;
    border-radius: 0px 20px 20px 0px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.signin-btn {
    width: 50%;
    outline: 0;
    border: 0;
    padding: 15px 5px;
    cursor: pointer;
    color: white;
    border-radius: 25px !important;
    background: linear-gradient(90deg, rgba(49, 199, 90, 1) 0%, rgba(99, 228, 85, 1) 100%);
}


/* mobile devices */
@media only screen and (max-width:688px) {
    .left-signIn {
        border-radius: 0;
        height: 200px;
        overflow: hidden;
    }

    .right-signIn {
        padding-bottom: 15px;
        border-radius: 0;
    }

    .main {
        margin-top: 100px;
    }


}

/* tablate devices */

@media only screen and (min-width:689px) and (max-width:992px) {}