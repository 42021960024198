@font-face {
  font-family: "DM Sans";
  src: url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&display=swap");
}
.section-one-parent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.parallax {
  width: 548.47px;
  height: 524.46px;
  display: flex;
  justify-content: center;
}

.parallax .left-image {
  width: 79.16px;
  height: 186.9px;
  margin-bottom: 100px;
}
.parallax .right-image {
  width: 79.16px;
  height: 186.9px;
  margin-bottom: 100px;
}

.parallax-effect-img {
  transform-style: preserve-3d;
  transform: perspective(1000px);

  .inner-element {
    transform: translateZ(40px);

    width: 326.6px;
    height: 508.76px;
  }
}

.healing {
  font-family: DM Sans, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: left;
}

.healing-text-content {
  width: 550px;
  height: 166px;
}
.left-image-div,
.right-image-div {
  display: flex;
  align-items: end;
}
.healing-button {
  display: flex;
  align-items: center;
  gap: 20px;
}
.healing-button-link {
  border-radius: 8px;
  border: 0px;
  background: #31c75a;
  width: 179px;
  height: 51px;
  padding: 10px, 16px, 10px, 16px;
  font-family: DM Sans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.healing-button-link:hover {
  background-color: white;
  cursor: pointer;
  color: #31c75a;
  transition: all;
  transition-duration: 0.1s;
}
.corporate-mental-health {
  border-radius: 8px;
  border: 0px;
  background: white;
  width: 300px;
  height: 51px;
  padding: 10px, 16px, 10px, 16px;
  font-family: DM Sans, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  /* color: #ffffff; */
  color: #00000080;
}
.corporate-mental-health:hover {
  background-color: white;
  cursor: pointer;
  color: #31c75a;
  transition: all;
  transition-duration: 0.1s;
}
@media only screen and (max-width: 1200px) {
  .parallax {
    width: 50%;
    margin: auto;
  }
  .healing-text-content {
    margin-bottom: 30%;
  }
  .corporate-mental-health {
    font-size: 20px;
  }
  .section-one-parent {
    width: 90%;
    margin: 0 auto;
  }
  .healing {
    font-size: 63px;
  }
}
@media only screen and (max-width: 600px) {
  .parallax {
    width: 90%;
    /* margin-top: 20%; */
  }
  .parallax-effect-img .inner-element {
    width: 100%;
  }
  .parallax .left-image {
    width: 90%;
  }
  .parallax .right-image {
    width: 90%;
  }
  .healing-text-content {
    width: 100%;
    margin: 0% auto;
    /* margin-bottom: 30%; */
    height: 400px;
    /* padding: 0 3%; */
  }
  .corporate-mental-health {
    font-size: 24px;
  }
  .healing-text-content .healing {
    width: 100%;
    /* font-size: 60px; */
  }
}
@media only screen and (max-width: 500px) {
  .parallax {
    width: 100%;
    margin: 0% auto;
  }
  .corporate-mental-health {
    font-size: 17px;
    text-wrap: nowrap;
    width: 100%;
  }
  .parallax-effect-img .inner-element {
    width: 100%;
  }
  .healing-text-content {
    width: 100%;
    height: 300px;
  }
  .healing-text-content .healing {
    width: 100%;
    margin-top: 15%;
    margin-bottom: 10%;
    font-size: 40px;
    text-align: center;
    line-height: 1.3;
  }
  .healing-text-content .healing-button {
    /* display: none; */
    /* width: 100%; */
    /* margin: 0% auto; */
    /* font-size: 40px; */
    flex-direction: column;
  }
  .healing-button-link {
    font-size: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .healing-text-content {
    width: 95%;
    margin: 0 auto;
    height: 330px;
  }
  .corporate-mental-health {
    font-size: 14px;
  }
  .healing-button-link {
    font-size: 15px;
    width: 155px;
    text-wrap: nowrap;
  }
}
@media only screen and (max-width: 360px) {
  .corporate-mental-health {
    font-size: 15px;
    text-wrap: wrap;
    width: 100%;
  }
  .healing-button-link {
    width: 100%;
    font-size: 15px;
    text-wrap: nowrap;
  }
  .healing-text-content {
    width: 100%;
    height: 200px;
  }
  .healing-text-content .healing {
    width: 100%;
    margin-top: 15%;
    margin-bottom: 0%;
    line-height: 60px;
    font-size: 35px;
  }
  .healing-text-content .healing-button {
    width: 100%;
    margin: 0% auto;
  }
}

@media only screen and (max-width: 332px) {
  .healing-text-content {
    width: 100%;
    height: 200px;
  }
  .corporate-mental-health {
    /* width: 100%; */
    text-wrap: nowrap;
  }
}

@media only screen and (max-width: 305px) {
  .healing-text-content {
    width: 95%;
    margin: 0 auto;
    height: 160px;
  }
  .corporate-mental-health {
    /* width: 100%; */
    /* text-wrap: nowrap; */
    /* text-wrap: wrap; */
    font-size: 14px;
  }
  .healing-text-content .healing {
    font-size: 20px;
    line-height: 50px;
  }
}
