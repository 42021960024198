.journal {
  margin: 2rem 0 6rem;
}

.journal img {
  cursor: pointer;
  pointer-events: all;
}

.journal textarea {
  width: 100%;
  height: 200px;
  resize: none;
  outline: none;
  border: 1px solid #ddd;
  padding: 1rem;
}

.journal .selectedJournal {
  margin-top: 2rem;
  border-radius: 0.8rem;
  padding: 2rem;
  background-color: #e6f1ec;
}

.journal .selectedJournal p {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.journal .selectedJournal p span:first-child {
  flex-basis: 200px;
  font-weight: 700;
}
.journal .selectedJournal p span:last-child {
  flex-basis: calc(100% - 200px);
}

@media screen and (max-width: 980px) {
  .journal .selectedJournal p {
    flex-direction: column;
    gap: 0;
  }
  .journal .selectedJournal p span:first-child {
    flex-basis: 0%;
  }

  .pad_left {
    padding-left: 0;
  }
}
