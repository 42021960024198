.student-packages-header {
  font-family: DM Sans, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: center;
  color: #231d4f;
}
.hover-cards:hover {
  transform: translateY(-50px) !important;
}

.student-packages-header-details {
  font-family: DM Sans, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #848199;
}
.gift-card-features {
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 17px !important;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.gift-card-name {
  font-family: DM Sans, sans-serif;
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 40px !important;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize;
  /* display: block; */
  color: #231d4f;

  margin-top: 20px !important;
}
.student-seeking {
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #848199;
}
.student-2-seeking {
  color: white;
}
.gift-card-3-name {
  color: white;
}
.gift-card-popular {
  font-family: DM Sans, sans-serif;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0.906512975692749px !important;
  text-align: center;
  color: white;
  background: #393939;
  padding: 10px 30px;
  border-radius: 100px;
  text-transform: uppercase;
}
.gift-card-value {
  font-family: DM Sans, sans-serif;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0.906512975692749px !important;
  text-align: center;
  color: white;
  background: black;
  padding: 10px 30px;
  border-radius: 100px;
  text-transform: uppercase;
}
.gift-card-green-value {
  font-family: DM Sans, sans-serif;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0.906512975692749px !important;
  text-align: center;
  color: white;
  background: #36ca5a;

  padding: 10px 30px;
  border-radius: 100px;
  text-transform: uppercase;
}
.gift-card-gold-value {
  font-family: DM Sans, sans-serif;
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  letter-spacing: 0.906512975692749px !important;
  text-align: center;
  color: white;
  /* background: #36ca5a;
   */
  background: radial-gradient(
      97.89% 97.89% at 81.73% 2.11%,
      #f9f19c 0%,
      #be953b 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  padding: 10px 30px;
  border-radius: 100px;
  text-transform: uppercase;
}
.gift-card-price {
  font-family: DM Sans, sans-serif;
  font-size: 39px !important;
  font-weight: 700 !important;
  line-height: 50px !important;
  letter-spacing: 0px;
  text-align: left;

  color: #231d4f;
}
.gift-card-3-price {
  color: white;
}
.premium-gray-rectangle {
  background: #66513b !important;
}

.premium-green-rectangle {
  background: #36ca5a !important;
}
.premium-gold-rectangle {
  background: radial-gradient(
    97.89% 97.89% at 81.73% 2.11%,
    #f9f19c 0%,
    #be953b 100%
  ) !important
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.gift-card-btn-custom {
  display: flex;
  justify-content: center;
}
.gift-card-button-custom {
  width: 85%;
  height: 48.95px;
  background: #707070;

  transform: translateZ(0);
  /* padding: 10px; */
  border-radius: 61px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}
.gift-card-button-custom:hover {
  background: #ffffff;
  transform: scale(0.9);
}

.gift-card-button-custom p {
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
}
.gift-card-button-custom:hover p {
  color: #686868;
}
.gift-card-friends-button:hover p {
  color: #66513b !important;
}
.gift-card-green-button:hover p {
  color: #36ca5a !important;
}
.gift-card-gold-button:hover p {
  color: #e1bf46 !important;
}
.gift-card-3-features {
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}
.gift-card-first-button {
  position: absolute;
  bottom: 30px;
  width: 71.5% !important;
}
.gift-card-last-button {
  position: absolute;
  bottom: 25px;
  width: 71.5% !important;
}
.card-translate-animation {
  width: 100%;
  border: none !important;
  outline-style: none !important;
  box-shadow: none !important;
  padding: 0px 10px;
  border-radius: 30px !important;
  transition: transform 0.3s, box-shadow 0.3s !important;
}
.first-second-card:hover {
  background: #66513b !important;
}
.shadow-rectangle {
  box-shadow: 0px 15px 12px 0px #c6c6c6 !important;
}
/* .card-translate-animation:hover {
  transform: translateY(-50px) !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2) !important;
} */

@media only screen and (max-width: 900px) {
  /*Big smartphones [426px -> 600px]*/
  .middle-card-translate {
    transform: translateY(0px) !important;
  }
}
