.logo {
    width: 44%;
}

.footer-lastBox {
    width: 40%;
    display: flex;
    flex-direction: row;
    place-items: center;

}

.second-footer {
    background-color: #105157;
}

.info {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    place-items: center !important;
    align-self: flex-start !important;
}

.social_container {
    display: flex;
    align-items: center;
}

.social {
    cursor: pointer;
}

.social.youtube {
    width: 32px;
    height: 32px;
}

.newsletter {
    color: #fff;
    padding-left: 3rem;
}

.newsletter_form {
    margin-right: 2rem;
}

.newsletter .form input{
    width: 100%;
    padding: 1rem;
    margin-bottom: .5rem;
    border-radius: 12px;
}

.newsletter .form .submit {
    background-color: #31C75A;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 12px;
}
.newsletter .form input:focus {
    outline: none;
}


.form {
    display: grid;
    gap: 3px;
    grid-template-columns: 3fr 1fr;
}


/* =============== Dashboard Footer ============== */

.dashboard_footer {
    color: white; 
    background-color: #105157; 
    display: flex; 
    gap: 2rem; 
    justify-content: center;
    padding: 1rem; 
    text-align: center;
    align-items: center;
}



@media screen and (max-width: 680px) {
    .newsletter {
        text-align: center;
        padding: 0 1rem 0 2rem;
    }

    .newsletter_form {
        margin-right: 1rem;
    }

    .details {
        display: flex !important;
        flex-direction: column  !important;
        align-items: center  !important;
        justify-content: center  !important;
    }
}